import { useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import { IDotColorProps } from '~/core/ui/Dot'
import { ISelectOption } from '~/core/ui/Select'
import { PLACEMENT_COLOR_STATUS } from '~/lib/features/placements/utilities/enum'

const usePlacementStatusWithDotColor = () => {
  const { i18n } = useTranslation()
  const typeOfStatus = useEnumsData({
    enumType: 'PlacementStatus',
    locale: i18n.language
  })
  return typeOfStatus.map((item: ISelectOption) => ({
    ...item,
    dot: PLACEMENT_COLOR_STATUS(item.value).color as IDotColorProps
  }))
}
export default usePlacementStatusWithDotColor
