import { type ComponentProps, useMemo, type ReactElement, useRef } from 'react'

import { create } from 'zustand'
import { Button } from '~/core/ui/Button'
import { LinkButton } from '~/core/ui/LinkButton'

export type SimpleSubmitModalType<ModalFormData> = {
  open: boolean
  setOpen: (open: boolean) => void
  onSubmit: (data: ModalFormData) => Promise<unknown> | void
}

const createModalHook = <
  SubmitFormValue extends object,
  ModalExtendProps,
  ExtraOpenParams extends object
>(
  ModalBodyComponent: (
    props: { openParams?: ExtraOpenParams } & ModalExtendProps &
      SimpleSubmitModalType<SubmitFormValue>
  ) => ReactElement | null
) => {
  const createModalStore = () =>
    create<{
      open: boolean
      setOpen: (open: boolean) => void
      extraParams?: ExtraOpenParams
    }>((set) => ({
      open: false,
      setOpen: (open: boolean, params?: ExtraOpenParams) =>
        set((state) => ({
          open,
          extraParams: {
            ...state.extraParams,
            ...(params ?? {})
          } as ExtraOpenParams
        }))
    }))
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const useModalStore = useMemo(() => createModalStore(), [])

  return (
    onSubmitModal: SimpleSubmitModalType<SubmitFormValue>['onSubmit']
  ) => {
    const paramsRef =
      useRef<SimpleSubmitModalType<SubmitFormValue>['onSubmit']>(onSubmitModal)
    paramsRef.current = onSubmitModal
    return useMemo(
      () => ({
        ModalComponent: (
          props: Omit<ModalExtendProps, 'open' | 'setOpen' | 'onSubmit'>
        ) => {
          const { open, setOpen, extraParams } = useModalStore()
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            <ModalBodyComponent
              {...props}
              open={open}
              setOpen={setOpen}
              onSubmit={(data) => paramsRef.current(data)}
              openParams={extraParams}
            />
          )
        },
        Button: (props: ComponentProps<typeof Button>) => {
          const { setOpen } = useModalStore()
          return (
            <Button
              {...props}
              // type="button"
              onClick={(e) => {
                setOpen(true)
                if (props.onClick) props.onClick(e)
              }}
            />
          )
        },
        LinkButton: (props: ComponentProps<typeof LinkButton>) => {
          const { setOpen } = useModalStore()
          return (
            <LinkButton
              {...props}
              // type="button"
              onClick={(e) => {
                setOpen(true)
                if (props.onClick) props.onClick(e)
              }}
            />
          )
        },
        openModal: (params: ExtraOpenParams) => {
          useModalStore.setState((state) => ({
            ...state,
            open: true,
            extraParams: params
          }))
        }
      }),
      []
    )
  }
}
export default createModalHook
