import { useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { IPromiseSearchOption } from '~/core/ui/Select'
import QueryTenantMembers from '~/lib/features/settings/members/graphql/query-tenant-members'
import { IMember } from '~/lib/features/settings/members/types'
import usePlacementStatusWithDotColor from './use-placement-status-with-dot-color'

const usePlacementForm = () => {
  const { i18n } = useTranslation()
  const { clientGraphQL } = useContextGraphQL()
  const fetchHiringMembers = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      return clientGraphQL
        .query(QueryTenantMembers, {
          ...params
        })
        .toPromise()
        .then((result: IResponseContextResult<IMember>) => {
          if (result.error) {
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { tenantMembers } = result.data
          const collection = tenantMembers?.collection || []
          const metadata = tenantMembers?.metadata || {}

          const cloneData = collection.map((item: IMember) => {
            return {
              value: item.id,
              avatar: item.avatarVariants?.thumb?.url,
              avatarVariants: item.avatarVariants,
              defaultColour: item.defaultColour,
              supportingObj: {
                name: item.fullName,
                description: item.email,
                defaultColour: item.defaultColour,
                helpName: item.roles?.[0]?.name
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })
  const placementTypeOfFees = useEnumsData({
    enumType: 'PlacementTypeOfFee',
    locale: i18n.language
  })
  const typesOfSalary = useEnumsData({
    enumType: 'PlacementTypeOfSalary',
    locale: i18n.language
  })
  const typeOfStatus = usePlacementStatusWithDotColor()
  return {
    fetchHiringMembers,
    placementTypeOfFees,
    typesOfSalary,
    typeOfStatus
  }
}
export default usePlacementForm
