import { useEffect } from 'react'
import { Tooltip } from '~/core/ui/Tooltip'

const ToolTipOnOverflow = ({
  className,
  text
}: {
  className?: string
  text?: string
}) => {
  useEffect(() => {}, [text])
  return (
    <Tooltip classNameAsChild={className} content={text}>
      <div className={className}>{text}</div>
    </Tooltip>
  )
}
export default ToolTipOnOverflow
