'use client'

import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { cn } from '~/core/ui/utils'

const linkButtonRootVariants = cva(
  'flex items-center justify-center hover:underline focus:underline',
  {
    variants: {
      variant: {
        primaryWithDisabled:
          'pointer-events-none text-primary-200 dark:text-primary-800',
        secondaryTertiaryWithDisabled:
          'pointer-events-none text-gray-400 dark:text-gray-600',
        primaryNoDisabled:
          'text-primary-400 hover:text-primary-400 focus:text-primary-400',
        secondaryNoDisabled:
          'text-gray-600 hover:text-gray-600 focus:text-gray-600 dark:text-gray-300 dark:hover:text-gray-300 dark:focus:text-gray-300',
        tertiaryNoDisabled:
          'text-gray-500 hover:text-gray-500 focus:text-gray-500 dark:text-gray-400 dark:hover:text-gray-400 dark:focus:text-gray-400'
      },
      icon: {
        leading: 'flex-row',
        trailing: 'flex-row-reverse'
      }
    },
    defaultVariants: {
      variant: 'primaryNoDisabled',
      icon: 'leading'
    }
  }
)

const linkButtonLabelVariants = cva('', {
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-base'
    }
  },
  defaultVariants: {
    size: 'md'
  }
})

const linkButtonIconVariants = cva('', {
  variants: {
    size: {
      4: 'mr-1.5',
      3: 'mr-1',
      2: 'ml-1.5',
      1: 'ml-1'
    }
  },
  defaultVariants: {
    size: 4
  }
})

const getLinkButtonSpaceCondition = ({
  size = 'md',
  icon
}: {
  size?: 'xs' | 'sm' | 'md'
  icon?: 'leading' | 'trailing'
}) => {
  if (['md', 'sm'].includes(size) && icon === 'leading') return 4
  if (size === 'xs' && icon === 'leading') return 3
  if (['md', 'sm'].includes(size) && icon === 'trailing') return 2
  if (size === 'xs' && icon === 'trailing') return 1

  return 4
}

const getLinkButtonVariantColorCondition = ({
  type = 'primary',
  isDisabled = false
}: {
  type?: 'primary' | 'secondary' | 'tertiary'
  isDisabled?: boolean
}) => {
  if (isDisabled == true && type === 'primary') return 'primaryWithDisabled'
  if (isDisabled == true && ['secondary', 'tertiary'].includes(type))
    return 'secondaryTertiaryWithDisabled'
  if (isDisabled == false && type === 'primary') return 'primaryNoDisabled'
  if (isDisabled == false && type === 'secondary') return 'secondaryNoDisabled'
  if (isDisabled == false && type === 'tertiary') return 'tertiaryNoDisabled'

  return 'primaryNoDisabled'
}

const getLinkButtonSizeIcon = {
  md: 18,
  sm: 16,
  xs: 14
}

interface LinkButtonProps {
  type?: 'primary' | 'secondary' | 'tertiary'
  size?: 'xs' | 'sm' | 'md'
  label?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  isDisabled?: boolean
  icon?: 'leading' | 'trailing'
  iconMenus?: LucideIconName
  fontWeight?: string
}

const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>(
  (props, ref) => {
    const {
      label,
      type = 'primary',
      size = 'md',
      isDisabled = false,
      icon,
      onClick,
      iconMenus,
      fontWeight = 'font-medium'
    } = props
    return (
      <button
        ref={ref}
        type="button"
        onClick={(event) => {
          if (onClick) {
            onClick(event)
          }
        }}
        className={cn(
          linkButtonRootVariants({
            variant: getLinkButtonVariantColorCondition({ type, isDisabled }),
            icon
          })
        )}>
        {iconMenus && (
          <IconWrapper
            className={cn(
              linkButtonIconVariants({
                size: getLinkButtonSpaceCondition({ size, icon })
              })
            )}
            size={getLinkButtonSizeIcon[size]}
            name={iconMenus}
          />
        )}
        <span
          className={cn(
            linkButtonLabelVariants({ size, className: fontWeight })
          )}>
          {label}
        </span>
      </button>
    )
  }
)

LinkButton.displayName = 'LinkButton'

export { LinkButton }
export type { LinkButtonProps }
