export type IPlacementStatusType = {
  label: string
  id: string
  colorClassName: string
}

export const PLACEMENT_COLOR_STATUS = (status: string) => {
  const lowercaseStatus = status.trim().toLowerCase().replaceAll(' ', '_')
  let obj = { key: 'cancelled', color: 'red' }

  switch (lowercaseStatus) {
    case 'not_invoiced':
      obj = { key: 'not_invoiced', color: 'gray' }
      break
    case 'paid_partially':
      obj = { key: 'not_invoiced', color: 'yellow' }
      break
    case 'paid_fully':
      obj = { key: 'paid_fully', color: 'green' }
      break
    default:
      break
  }

  return obj
}
export const PLACEMENT_FEE_TYPE = (value: string) => {
  const lowercaseType = value.trim().toLowerCase().replaceAll(' ', '_')
  if (lowercaseType === 'percentage') return { key: 'percentage' }
  if (lowercaseType === 'months') return { key: 'months' }
  return { key: 'default' }
}
